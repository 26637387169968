import Banner from './components/banner';
import Who from './components/who';
import Team from './components/team';
import Updates from './components/updates';
import Contact from './components/contact';
import WhatWeOffer from './components/whatWeOffer';
import './homeStyles.scss';

import Nav from '../../components/navbar';
import Articles from './components/articles';
import Partners from './components/partners';
const Home = () => {
    return (
        <div>
            <Nav />
            <Banner />
            <div style={{ width: '100vw', marginBottom: '4vh' }}>
                <Who />
                <WhatWeOffer />
                <Team />
                <Contact />
                <Updates />
                <Articles/>
                <Partners/>  
              
            </div>
            <div className='footer'> SamparkBindhu ™ 2021-22</div>
        </div>
    );
};

export default Home;
