import React from "react";

import { useTranslation } from "react-i18next";
import Group from "../../../assets/images/group.png";
import VINAY from "../../../assets/images/vinay.jpeg";
import { LeftOutlined } from "@ant-design/icons";
import NAVEEN from "../../../assets/images/naveen.jpeg";
import { Typography, Button, Row, Col, Carousel } from "antd";
import APOORVA from "../../../assets/images/apoorva.jpeg";
import SUDHESH from "../../../assets/images/spoorthi.jpeg";
import SPOORTHI from "../../../assets/images/spoorthi.jpeg";
import Ganesh from "../../../assets/images/rect_ganesh.png";
import OrangeLine from "../../../assets/images/orange_line.png";
import SatishChandra from "../../../assets/images/image-min.png";
const { Paragraph, Title } = Typography;
const TeamExtended = () => {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <div className="team_back_button">
        <Button
          size="small"
          icon={
            <span className="gradient-icon">
              <LeftOutlined />
            </span>
          }
          href="/"
        >
          <span className="gradient-text">Back</span>
        </Button>
      </div>
      <Row id="teamextended">
        <Row className="team_title">
          <div className="orange-box">
            <img src={Group} />
          </div>
          <Title className="teamTitle">
            <span className="first_word">Our</span> Team
          </Title>
          <img src={OrangeLine}></img>
        </Row>

        <Row className="team_sec">
          <Row justify="space-around" className="team_row">
            <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
              <img src={Ganesh}></img>
            </Col>
            <Col xs={24} sm={24} md={24} xl={14} xxl={14} className="teamPara">
              <Title className="teamName" level={5}>
                Ganesh M S
              </Title>

              <p>Ganesh M S</p>

              <Paragraph className="para">
                Over three decades of experience at backend operations in FMCG
                and lifestyle. He is helping us as an Advisor on sourcing and
                backend ops.
              </Paragraph>
            </Col>
          </Row>

          <Row justify="space-around" className="team_row">
            <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
              <img src={SatishChandra}></img>
            </Col>
            <Col className="teamPara" xs={24} sm={24} md={24} xl={14} xxl={14}>
              <Title className="teamName" level={5}>
                Satish Chandra C S
              </Title>

              <p>Agriculture Technology and Finance</p>

              <Paragraph className="para">
                Agriculture Technology and Finance Over three decades of
                experience as a Valuator, Finance researcher, Mentor in Agri
                Tech.As an advisor he is mentoring us on Agri Strategy, Roadmap
                and finance
              </Paragraph>
            </Col>
          </Row>

          <Row className="teamCarouselUnit">
            <Carousel>
              <div>
                <div className="teamContainer">
                  <img src={APOORVA}></img>
                  <Title className="teamName" level={5}>
                    Apoorva G
                  </Title>
                  <p>Backend Developer</p>
                </div>
              </div>
              <div>
                <div className="teamContainer">
                  <img src={SPOORTHI}></img>
                  <Title className="teamName" level={5}>
                    Spoorthi Kulkarni
                  </Title>
                  <p>Testing Engineer</p>
                </div>
              </div>
              <div>
                <div className="teamContainer">
                  <img src={SUDHESH}></img>
                  <Title className="teamName" level={5}>
                    Sudesh Vantimar S
                  </Title>
                  <p>Frontend Developer</p>
                </div>
              </div>
              {/* <div>
                <div className="teamContainer">
                  <img src={VINAY}></img>
                  <Title className="teamName" level={5}>
                    {t("our_team.sales.name.1")}
                  </Title>
                  <p>{t("our_team.sales.designation.1")}</p>
                </div>
              </div> */}
              <div>
                <div className="teamContainer">
                  <img src={NAVEEN}></img>
                  <Title className="teamName" level={5}>
                    Naveen
                  </Title>
                  <p>Sales and Operation</p>
                </div>
              </div>
            </Carousel>
            <p className="teamDescription">
              Agriculture Technology and Finance Over three decades of
              experience as a Valuator, Finance researcher, Mentor in Agri
              Tech.As an advisor he is mentoring us on Agri Strategy, Roadmap
              and finance
            </p>
          </Row>
        </Row>
      </Row>
    </React.Fragment>
  );
};

export default TeamExtended;
