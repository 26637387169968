import React from 'react';
import JIT from '../../../assets/images/jit.png';
import SVCE from '../../../assets/images/svce.png';
import Title from 'antd/lib/typography/Title';
import Group from '../../../assets/images/group.png';
import IIITB from '../../../assets/images/iiitb.png';
import MEITY from '../../../assets/images/meity.png';
// import SKIT from '../static/assets/skit.png';
import C_Camp from '../../../assets/images/c-camp.png';
import UASB from '../../../assets/images/uas_blore.png';
import RAFTAAR from '../../../assets/images/raftaar.png';
import UASD from '../../../assets/images/uas_dharawad.png';
import BlueLine from '../../../assets/images/blue_line.png';
import INDIA from '../../../assets/images/startup_india.png';
import KARNATAKA from '../../../assets/images/startup_karnataka.png';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default function Partners() {
    return (
        <React.Fragment>
            <div id="partners" className="partners">
                <div className="blue-box">
                    <img src={Group} />
                </div>
                <Title className="partnerTitle">
                    <span className="first_word">Our</span> Partners
                </Title>
                <img src={BlueLine} alt="blue_line" />
                <div className="web-visible-partners">
                <Carousel autoplay arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />} dots={false}
                >
                    <div className='inside-carousel'>
                        <img src={C_Camp}></img>
                        <img src={MEITY}></img>
                        <img src={RAFTAAR}></img>
                        <img src={KARNATAKA}></img>
                        <img src={INDIA}></img>
                    </div>
                    <div className='inside-carousel'>
                        <img src={JIT}></img>
                        <img src={SVCE}></img>
                        <img src={UASD}></img>
                        <img src={UASB}></img>
                        <img src={IIITB}></img>
                    </div>
                </Carousel>
                </div>

                <div className="partners-phone-display">
                    <Carousel autoplay dots={false}>
                        <div className="partner-carousel-item">
                            <img src={C_Camp}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={MEITY}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={RAFTAAR}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={KARNATAKA}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={INDIA}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={JIT}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={SVCE}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={UASD}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={UASB}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={IIITB}></img>
                        </div>
                    </Carousel>
                </div>
            </div>
        </React.Fragment>
    );
}