import { Row, Col, Image, Typography } from "antd";

import image1 from "../../../assets/images/image1.png";
import LandingImage from "../../../assets/images/Landingimage.jpg";
import vikasLogo from "../../../assets/images/vikas-logo.jpg";
import engageLogo from "../../../assets/images/engage-logo.jpg";
import Head from "../../../components/sectionHead";

const { Title: AntTitle, Paragraph } = Typography;

export default function whatweoffer() {
  return (
    <div className="contact-wrapper container-fluid" id="whatweoffer">
      <Head title={"What We Offer"} />
      <Row className="vikasbandhu-row" gutter={36} align="middle">
        <Image className="image-web" preview={false} src={image1} />
        <Col span={12}>
          <Row className="title-row" align="middle" justify="start">
            <Image src={vikasLogo} preview={false} />
            <AntTitle className="title">VikasBandhu</AntTitle>
            <Image className="image-mobile" preview={false} src={image1} />
          </Row>
          <Row className="details-row" align="middle" justify="start">
            <AntTitle level={4} className="what-we-offer-title">
              Today
            </AntTitle>
            <Paragraph>
              There is a large disparity in price of produce between farmer and
              retail. The overheads associated with Supply Chain, Transport,
              Wastage, Storage, packing, seasonal vagaries add up the costs. The
              outcome results in high disparity with low prices to the farmer
              and high prices to the end consumer. Government policies were
              recently amended to allow direct selling of produce. Adoption is
              however very limited.
            </Paragraph>
            <AntTitle level={4} className="what-we-offer-title">
              Philosophy
            </AntTitle>
            <Paragraph className="paragraph">
              Attempts to deal with this constraints from multiple dimensions
              <ul>
                <li>
                  Create a new efficient, optimized technology driven market
                  place
                </li>
                <li>Enable the farmer with the best price for his produce.</li>
                <li>
                  Boosting Rural economy by providing local employment to youth
                  in all districts in Karnataka
                </li>
                <li>
                  VikasBandhu would be single point to provide all the local
                  support to the farmers from sow to sell
                </li>
                <li>
                  VikasBandhu would provide the right linkage to the buyer for
                  getting the produce at the most efficient price.
                </li>
                <li>Enable a Win Win for Both the farmer and the buyer</li>
              </ul>
              Visit at:{" "}
              <a
                href="https://vikasbandhu.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> vikasbandhu.in</strong>
              </a>
              &nbsp;Or download our&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=com.vikasBandhu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Vikasbandhu Android App</strong>
              </a>
            </Paragraph>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Row className="engageall-row" gutter={36} align="middle">
        <Col span={12}>
          <Row className="title-row" align="middle" justify="start">
            <Image src={engageLogo} alt="logo" preview={false} />
            <AntTitle level={1} className="title">
              Engage All
            </AntTitle>
            <Image className="image-mobile" preview={false} src={LandingImage} />
          </Row>
          <Row className="details-row" align="middle" justify="start">
            <AntTitle level={4} className="what-we-offer-title">
              Today
            </AntTitle>
            <Paragraph>
            An B2B CRM platform for Rural Industries and Farmer Organizations to
            Handle the everyday needs of Bulk inventory across Warehouses,
            Stores, and Gowdowns
            </Paragraph>
            <AntTitle level={4} className="what-we-offer-title">
              Philosophy
            </AntTitle>
            <Paragraph>
              This is an Ideal B2B CRM platform for Rural Industries (FPOs,
              Societies, Stockists, Godowns, Wholesalers, retailers, Logistics,
              etc) It handles the everyday needs of bulk inventory across
              multiple warehouses, godowns, and stores. Ensuring timely stock
              replenishment and inventory turnover, coupled with data-driven
              strategies for needed reports providing insights for efficiency
              and profitability It is driven by the ease of use approach proving
              Management of Godowns/Stores, Stocks, and Orders with the needed
              GST compliance
            </Paragraph>
            Visit at:{" "}
              <a
                href="https://engageall.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> engageall.in</strong>
                
              </a>
            
              &nbsp;Or download our&nbsp;
              <a
               
              >
                <strong >EngageAll Android App</strong>
              </a>
              
          </Row>
        </Col>
        <Col span={12} className="image-web">
          <Image preview={false} src={LandingImage} />
        </Col>
      </Row>
    </div>
  );
}
