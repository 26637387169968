import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'antd/dist/antd.css';

import './App.scss';
import Home from './pages/home/home';
import TechCorner from './pages/home/components/techCorner';
import TeamExtended from './pages/home/components/teamExtended';
function App() {
    return (
        <div className='App'>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/techcorner' element={<TechCorner />} />
                    <Route path='/teamextended' element={<TeamExtended/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
