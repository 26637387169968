import React from 'react';
import { Breadcrumb, Image, Tag } from 'antd';
import './customStyles.scss';
import MobileDrawer from './mobileDrawer';

export default function Navbar() {
    return (
        <React.Fragment>
            <nav className='navbar'>
                <Image
                    style={{ minWidth: '17vw' }}
                    src='./logo.png'
                    alt=''
                    className='navbar-image'
                    preview={false}
                />
                <Breadcrumb className='breadcrumb' separator='&nbsp;&nbsp;&nbsp;' style={{ position: 'relative', left: '5vw' }}>
                    <Breadcrumb.Item href='#whoWeAre'>Who We Are</Breadcrumb.Item>
                    <Breadcrumb.Item href='#whatweoffer'>What We Offer</Breadcrumb.Item>
                    <Breadcrumb.Item href='#team'>Mgmt. Team</Breadcrumb.Item>
                    <Breadcrumb.Item href='#contact'>Contact Us</Breadcrumb.Item>
                    <Breadcrumb.Item href='https://vikasbandhu.in/terms&conditions'>
                        Terms
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='#updates'>
                        Updates
                        <Tag
                            color='#e6b31e'
                            style={{
                                fontSize: 'x-small',
                                borderRadius: '5vw',
                                bottom: '2vh',
                                position: 'relative'
                            }}
                        >
                            New
                        </Tag>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='#articles'>Articles</Breadcrumb.Item>
                    <Breadcrumb.Item href='#partners'>Partners</Breadcrumb.Item>
                </Breadcrumb>
                <MobileDrawer />
            </nav>
        </React.Fragment>
    );
};
